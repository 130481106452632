/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-26",
    versionChannel: "nightly",
    buildDate: "2024-09-26T00:06:21.934Z",
    commitHash: "732e2b5e5523c031b8af2a349d4afe8e5b96b71a",
};
